

import React from 'react'
import SeoPage from '../components/SeoPage'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/etapes-dossier-adoption-quebec-scaled.jpg'
import { graphql } from 'gatsby'
import ConversionLink from '../components/ConversionLink'


const BlogPost20 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Comment fonctionne le processus d’adoption au Québec? Entamez la démarche avec un avocat! - Soumissions Avocat"
        description="Vous désirez entamer une démarche d’adoption? Consultez dès maintenant un avocat en droit de la famille!"
        image={LeadImage}>
        <h1>Comment fonctionne le processus d’adoption au Québec? Entamez la démarche avec un avocat!</h1>

					
					
						<p>Adopter un enfant représente une démarche remplie d’humanité et de vertu. Qu’à cela ne tienne, c’est également un processus fastidieux, complexe et nécessitant de côtoyer la sphère juridique de près.  Il est donc essentiel de s’aventurer dans la démarche d’adoption avec <strong>le bon allié à vos côtés, soit l’avocat en droit de la famille!</strong></p>
<p><StaticImage alt="etapes dossier adoption quebec" src="../images/etapes-dossier-adoption-quebec-scaled.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Un jugement d’adoption ne peut être prononcé que s’il est dans l’intérêt de l’enfant de le faire</strong>. Ce sera donc le rôle de votre avocat de vous aider à accomplir chaque formalité légale afin de démontrer avec conviction au juge que l’enfant que vous désirez adopter sera épanoui avec vous.</p>
<p><strong>Contactez Soumissions Avocat afin d’être mis en contact avec un avocat familialiste spécialisé dans les dossiers d’adoption!</strong></p>
<h2>Quelles sont les conditions pour adopter un enfant au Québec?</h2>
<p>Les conditions de l’adoption au Québec demeurent les mêmes, quel que soit le statut de votre couple, puisqu’elles sont toutes dirigées vers la protection du meilleur intérêt de l’enfant. Des délais minimums de vie commune s’appliqueront toutefois aux couples en union de fait. Voici donc les critères à rencontrer pour obtenir un jugement d’adoption à l’issue de votre requête en adoption!</p>
<p><strong>Vous devez avant tout être majeur et âgé de 18 ans de plus de que l’enfant adopté. </strong>Cette règle connait toutefois une exception et ne s’applique pas lorsque vous adoptez l’enfant de votre voisin par consentement spécial.</p>
<p>De plus, l’adoption doit uniquement être prononcée lorsqu’elle est dans<strong> l’intérêt de l’enfant. </strong>Cette analyse prend notamment en compte les besoins physiques, moraux et affectifs de ce dernier. Plus l’enfant sera âgé, plus on <strong>prendra en compte de l’opinion de l’enfant.</strong></p>
<p>Notamment, lorsque l’enfant est âgé de 10 ans et plus, son avis doit être obtenu concernant le processus d’adoption. Le juge aura l’occasion d’évaluer le sérieux de son refus et de l’écarter ou non. En revanche, le refus d’adoption d’un enfant âgé de 14 ans et plus met en échec le processus d’adoption du tout au tout.
<strong>
</strong>Il est également impératif d’obtenir<strong> le consentement des parents biologiques, </strong>qu’il soit général ou spécial, sauf lorsqu’un juge a déclaré la déchéance de l’autorité parentale de ces mêmes parents. Effectivement, un des effets secondaires de la déchéance est que l’enfant est à risque d’être mis en adoption. Pareil scénario engendre évidemment un risque élevé de contestation, surtout si le parent biologique déchu souhaite récupérer ses droits.</p>
<p><strong>Le cas particulier de l’adoption par le conjoint du parent doit également être considéré. </strong>Pour qu’un jugement d’adoption soit prononcé en faveur de ce dernier, il doit y avoir cohabitation avec le parent de l’enfant depuis au moins 3 ans.</p>
<p><strong>Comment devrez-vous prouver ces conditions?</strong> Avec l’aide d’un avocat qui montera votre dossier de façon à convaincre un juge qu’il est dans le meilleur intérêt de l’enfant d’être confié à vos soins, et ce, de façon permanente.</p>
<h2>Les étapes administratives et judiciaires de l’adoption avec un avocat!</h2>
<p>Il est fort compréhensible qu’on ne prononce pas un jugement d’adoption du jour au lendemain et de façon précipitée. Il faut passer au travers de nombreuses étapes prévues par l’appareil judiciaire pour assurer que l’adoption est prononcée en faveur de parents dignes et dans le respect des intérêts de l’enfant. Voici les étapes qui vous attendent à l’occasion de ce processus!</p>
<p><StaticImage alt="requete adoption placement avocat" src="../images/requete-adoption-placement-avocat-scaled.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Déclaration d’admissibilité à l’adoption : </strong>La première étape à respecter dans le processus d’adoption consiste à obtenir une déclaration d’admissibilité auprès du tribunal. Il faudra invoquer les motifs prévus au Code civil du Québec afin d’obtenir la déclaration d’admissibilité, et seuls les parents en ligne directe ou collatérale jusqu’au troisième degré peuvent faire la demande. Cette déclaration oblige également le tribunal à nommer la personne qui héritera de l’exercice de l’autorité parentale.
<strong>
L’ordonnance de placement : </strong>Une fois l’enfant admissible à l’adoption et le consentement donné par les parents, les adoptants potentiels doivent présenter une demande d’ordonnance de placement au tribunal. Lorsque le tribunal juge la requête appropriée, il ordonne le placement de l’enfant chez l’adoptant pour une période de 6 mois, période pendant laquelle ce dernier hérite de l’autorité parentale à l’égard de l’enfant. Il s’agit d’une période tampon pendant laquelle l’enfant ne peut pas être retourné auprès de ses parents biologiques ni auprès de son tuteur.</p>
<p><strong>Le jugement déclarant l’adoption : </strong>L’étape finale afin de cristalliser la situation consiste à présenter une requête en adoption au tribunal. Si le juge constate que l’enfant s’est adapté à son nouveau milieu familial, il prononce l’adoption de ce dernier. L’adoption a notamment pour effet de rompre toute filiation avec les parents biologiques et le nom de famille de l’enfant est conséquemment changé.</p>
<h2>Réponse à toutes vos questions fréquentes sur l’adoption au Québec en 2020!</h2>
<p>Force est d’admettre que l’adoption n’est pas une situation que l’on voit tous les jours. Il s’agit plutôt d’une démarche rencontrée dans des contextes uniques, ce qui peut faire surgir un lot de questionnement sans réponses. Afin de vous orienter vers la bonne direction, nous répondons donc à vos questions les plus fréquentes sur la démarche d’adoption au Québec en 2020!</p>
<p><StaticImage alt="questions frequentes adoption quebec" src="../images/questions-frequentes-adoption-quebec-scaled.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Est-ce que les couples homosexuels ont le droit d’adopter un enfant?
</strong>
Oui. Au Québec, les couplez homosexuels mariés ont le droit, au même titre que les couples hétérosexuels, d’adopter un enfant selon la même procédure légale. Ce faisant, lorsque le couple en question n’est pas marié, il devra démontrer une cohabitation de 3 ans avant d’entamer les procédures d’adoption. Pour ce qui est de l’adoption internationale, il faut toutefois se rabattre sur les lois spécifiques aux différents pays, lesquelles sont susceptibles de varier.</p>
<p><strong>Quelle différence entre le consentement général et spécial à l’adoption?
</strong>
Dans la loi québécoise, on prévoit deux mécanismes afin de consentir à l’adoption d’un enfant, soit le consentement général ou spécial. On qualifiera le consentement donné par un parent biologique comme général lorsque celui-ci permettra l’adoption de son enfant par toute personne éligible à l’adoption, sans spécifique d’adoptant précis.</p>
<p>Au contraire, l’adoption par consentement spécial consiste à désigner précisément la personne en faveur de qui l’adoption peut être ordonnée. Cependant, le consentement spécial ne peut être octroyé qu’en faveur d’un ascendant de l’enfant, d’un parent au troisième degré et du conjoint d’un des parents.</p>
<p><strong>Une personne majeure peut-elle être adoptée?</strong></p>
<p>Oui, mais le processus diffère du processus d’adoption traditionnel. En effet, un juge acceptera de prononcer l’adoption d’une personne majeure lorsque celle-ci y consent, que l’adoptant accepte et lorsque l’adoptant a joué un rôle de parent pendant la minorité de l’enfant désormais majeur. On doit donc être en présence d’une « adoption de fait » pendant la minorité qui se retrouvera consolidée à l’âge adulte.</p>
<p><strong>Les parents biologiques conservent-ils des droits vis-à-vis l’enfant? </strong></p>
<p><strong>
</strong>Non, le fait que la filiation de l’enfant ait été rompue par le jugement d’adoption fait en sorte que le parent ne jouit plus d’aucun droit à l’égard de son enfant. Cela s’applique également à l’égard de ses obligations alimentaires envers l’enfant.</p>
<p><strong>L’enfant adopté a-t-il le droit de retrouver ses parents biologiques?</strong></p>
<p>Au Québec, il n’existe pas de droit conféré à l’enfant de retrouver ses parents biologiques, puisque la règle veut que les dossiers d’adoption soient confidentiels et donc inaccessibles. À l’inverse, un parent biologique qui désirerait reprendre contact avec un enfant mineur ne peut en aucun cas communiquer avec lui alors qu’il est toujours mineur. Il doit attendre que ce dernier atteigne l’âge de la majorité.</p>
<p>Toutefois, les enfants désirant retrouver leurs parents biologiques, ou même leurs frères et sœurs adoptés, peuvent présenter des demandes de mise en contact auprès d’un Centre jeunesse. Cette organisation détient les informations nécessaires pour retracer les parents et consigne également les refus de mise en contact de ces derniers, le cas échéant.</p>
<p><strong>
Les effets de l’adoption sont-ils définitifs?</strong></p>
<p>Tous les effets de l’adoption sont permanents et définitifs. C’est donc dire que le lien de filiation avec l’ancien parent est brisé pour toujours et que ce dernier ne conserve aucun droit vis-à-vis l’enfant. Il subsiste la possibilité que l’enfant désire retrouver ses parents biologiques un jour, mais aucune obligation d’ordre juridique ne survit après le jugement.</p>
<p><strong>Vous avez toujours des questions en matière d’adoption au Québec ou à l’international? </strong>Pourquoi ne pas les poser directement à un avocat en droit de la famille dans votre région : contactez Soumissions Avocat et nous trouvons un tel expert en un clin d’œil!</p>
<h2>Trouvez votre avocat en droit de la famille pour amorcer votre démarche d’adoption!</h2>
<p>Vous êtes disposé à poser un geste qui changera la vie d’un enfant ainsi que la vôtre. Ne laissez surtout pas de simples formalités juridiques vous bloquer la route et engagez un avocat qualifié et expérimenté en matière d’adoption afin de concrétiser votre rêve sans embûche!</p>
<p><strong>Pour faciliter votre recherche d’avocats dans votre région, Soumissions Avocat vous propose de vous mettre en contact gratuitement avec un avocat qualifié près de chez vous.</strong></p>
<p><strong>Il ne vous reste qu’à remplir le formulaire en bas de page en expliquant les grandes lignes de votre dossier d’adoption et Soumissions Avocat se charge du reste!</strong></p>
    </SeoPage>
)
export default BlogPost20
  